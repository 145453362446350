import clsx from "clsx";
import type { HTMLAttributes, FunctionComponent } from "react";

import TextError from "../text/textError";

type WeightIconType =
  | "thin"
  | "light"
  | "regular"
  | "bold"
  | "fill"
  | "duotone";

type Icons = {
  size?: number | string;
  mirrored: boolean;
  weight: WeightIconType;
  color?: string;
};

const commonStyles = (
  disabled: boolean,
  leftIcon: FunctionComponent<Icons> | undefined,
) =>
  ` bg-white-5 flex w-full items-center self-stretch rounded-lg border text-white placeholder:text-white-70 ${leftIcon ? "pl-10" : "pl-4"} ${disabled && "cursor-not-allowed"}`;

const commonLabel = (disabled: boolean) =>
  `block text-textSm mb-2 ${disabled ? "text-white-20" : "text-white-80"}`;

const commonHelperText = (disabled: boolean) =>
  `mt-2 text-textSm ${disabled ? "text-white-20" : "text-white-80"}`;

const commonRightIcon = (disabled: boolean) =>
  `absolute inset-y-0 right-0 flex items-center pr-3 ${disabled && "text-white-20"}`;

const commonLeftIcon = (disabled: boolean) =>
  `pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${disabled && "text-white-20"}`;

const sizeType = {
  sm: "h-10 px-4 py-2 text-textSm",
  base: "h-11 py-3 px-4 text-textSm",
  lg: "h-12 py-3 px-4 text-textBase",
};

const sizeIconType = {
  sm: 12,
  base: 16,
  lg: 16,
};

const statusType = {
  default: "border-white-5",
  error: "border-danger-100",
  success: "border-success-100",
};

const focusType = {
  default:
    "focus:border focus:border-indigo-100 focus:ring-0 focus:bg-white-20",
  error: "focus:border focus:border-danger-100 focus:ring-0",
  success: "focus:border focus:border-success-100 focus:ring-0",
};

const helperTextColor = {
  error: "text-danger-100",
  success: "text-success-100",
};

interface FormInputProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  helpertext?: string;
  disabled?: boolean;
  required?: boolean;
  size?: keyof typeof sizeType;
  status?: "default" | "error" | "success";
  rightIcon?: FunctionComponent<Icons>;
  leftIcon?: FunctionComponent<Icons>;
  className?: string;
  classNameInput?: string;
  classNameLabel?: string;
  mirrored?: boolean;
  weightIcon?: WeightIconType;
  value?: string | number | undefined;
  error?: string;
  onChange?: (value: any) => void;
  colorIcon?: string;
}

const Input = ({
  id,
  name,
  type,
  label,
  placeholder,
  helpertext,
  disabled = false,
  required = false,
  size = "base",
  status = "default",
  rightIcon: RightIcon,
  leftIcon: LeftIcon,
  className,
  classNameInput,
  classNameLabel,
  mirrored = false,
  weightIcon = "regular",
  error,
  onChange,
  value,
  colorIcon = "white",
}: FormInputProps) => {
  const currentStatus = error ? "error" : status;

  const classNames = clsx("relative", className);

  const classNamesLabel = clsx(commonLabel(disabled), classNameLabel);

  const classNamesInput = clsx(
    commonStyles(disabled, LeftIcon),
    sizeType[size],
    focusType[currentStatus],
    statusType[currentStatus],
    classNameInput,
  );

  const classNamesHelperText = clsx(
    commonHelperText(disabled),
    helperTextColor[currentStatus],
  );

  const classNamesRightIcon = clsx(commonRightIcon(disabled));
  const classNamesLeftIcon = clsx(commonLeftIcon(disabled));

  const sizeIcon = sizeIconType[size];

  const handleOnChange = (item: any) => {
    if (onChange) {
      onChange(item);
    }
  };

  return (
    <>
      {label && (
        <label htmlFor={id} className={classNamesLabel}>
          {label}
        </label>
      )}
      <div className={classNames}>
        {LeftIcon && (
          <div className={classNamesLeftIcon}>
            <LeftIcon
              color={colorIcon}
              size={sizeIcon}
              mirrored={mirrored}
              weight={weightIcon}
            />
          </div>
        )}
        <input
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          aria-describedby={helpertext ? `${id}-helpertext` : undefined}
          className={classNamesInput}
          required={required}
          onChange={handleOnChange}
          value={value}
        />
        {RightIcon && (
          <div className={classNamesRightIcon}>
            <RightIcon
              color={colorIcon}
              size={sizeIcon}
              mirrored={mirrored}
              weight={weightIcon}
            />
          </div>
        )}
      </div>
      {error && <TextError>{error}</TextError>}
      {helpertext && (
        <p id={`${id}-helpertext`} className={classNamesHelperText}>
          {helpertext}
        </p>
      )}
    </>
  );
};

export default Input;
