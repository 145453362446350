import clsx from "clsx";

import Paragraph from "./paragraph";

interface TextErrorProps {
  children: React.ReactNode;
  classNameParagraph?: string;
  size?: "xs" | "sm" | "base" | "md" | "lg";
}

const commonParagraph = "text-danger-100";

export default function TextError({
  children,
  size = "sm",
  classNameParagraph,
}: TextErrorProps) {
  const classNamesParagraph = clsx(commonParagraph, classNameParagraph);

  return (
    <Paragraph size={size} className={classNamesParagraph}>
      {children}{" "}
    </Paragraph>
  );
}
