import { useState } from "react";

import { Check } from "@phosphor-icons/react";

import { useTranslations } from "@/i18n/utils";

import Button from "@/components/shared/buttons/button";
import Input from "@/components/shared/inputs/input";
import Paragraph from "@/components/shared/text/paragraph";

interface FormWaitingListProps {
  lang: "en" | "es";
}

const WAITING_URL = import.meta.env.PUBLIC_WAITING_LIST;

const FormWaitingList = ({ lang }: FormWaitingListProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<"error" | "default">("default");

  const t = useTranslations(lang);

  const BASE_TRANSLATE = "home.callToAction";

  const PLACEHOLDER = String(t(`${BASE_TRANSLATE}.input.placeHolder`));
  const BUTTON = String(t(`${BASE_TRANSLATE}.button`));
  const BUTTON_SUCCESS = String(t(`${BASE_TRANSLATE}.buttonSuccess`));
  const ERROR = String(t(`${BASE_TRANSLATE}.error.500`));
  const SUCCESS = String(t(`${BASE_TRANSLATE}.success`));

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email-waiting-list") as string;

    if (!email) return;

    try {
      const response = await fetch(WAITING_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, lang }),
      });

      if (response.ok) {
        setSuccess(true);
      } else if (response.status === 400) {
        setError("error");
      } else {
        throw new Error("Server error");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("error");
    }
  };

  return (
    <>
      <form
        id="form-waiting-list"
        method="POST"
        onSubmit={handleFormSubmit}
        className="col-span-full grid grid-cols-[4fr_2fr] gap-0 pb-3 pt-6 md:grid-cols-subgrid"
      >
        <Input
          className="md:col-span-3"
          classNameInput="rounded-r-none border-r-0"
          id="email-waiting-list"
          name="email-waiting-list"
          type="email"
          placeholder={PLACEHOLDER}
          status={error}
        />

        <Button
          buttonType="primary"
          type="submit"
          color="white"
          className="-ml-1 h-full md:col-span-1 md:min-w-32"
          size="base"
          weightIcon="bold"
          leftIcon={success && Check}
          label={success ? BUTTON_SUCCESS : BUTTON}
        />
      </form>
      {error === "error" && (
        <Paragraph size="xs" className="col-span-full text-danger-80">
          {ERROR}
        </Paragraph>
      )}
      {success && (
        <Paragraph size="xs" className="col-span-full text-white-70">
          {SUCCESS}
        </Paragraph>
      )}
    </>
  );
};

export default FormWaitingList;
